
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.wrapper {
  min-height: 80vh;
  border-top: 5px solid $color-error-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('https://cdn.coffeecircle.com/a82b6ad7-c42d-4585-98ca-56a0a6c3d43d/-/resize/1600x/-/quality/lighter/-/progressive/yes/-/format/auto/404-page-background.jpg')
    top center no-repeat;
  background-size: cover;
}

.container {
  max-width: 50rem;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
